import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import { RichText } from "../api/contentful"
import {
  Link,
  Section,
  BlockHeading,
  Pagehead,
  PageTitle,
  OpenerImage,
  Breadcrumbs,
  Narrow,
  InquiryCallToAction,
} from "../components/shared"
import { Architects, Carpentry, Digital } from "../components/illustrations"

const Grid = styled.section`
  display: grid;
  grid-column: 2;
  grid-template-columns: 1fr;
  gap: 2em;
  padding-bottom: 5em;
  @media only screen and (max-width: 800px) {
    padding: 0 1em 5em 1em;
  }
  @media only screen and (max-width: 666px) {
    grid-template-columns: 1fr;
    max-width: 80%;
  }
  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`

const FlexRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 2em;
  width: 100%;
  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
  @media only screen and (max-width: 666px) {
    flex-direction: column;
    &:nth-child(odd) {
      flex-direction: column;
    }
    gap: 0em;
  }
  @media only screen and (max-width: 480px) {
    &:nth-child(even) {
      &:first-of-type {
        background-color: red;
      }
    }
  }
`

const FlexRowIllu = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const FlexRowText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 1.2;
`

const Arbeitsweise = styled.section`
  grid-column: 2;
  padding-bottom: 5em;
  h3 {
    font-size: 2em;
    line-height: 1.1;
    margin: 0.1em 0;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 1em 5em 1em;
  }
`

const TwoColumns = styled.div`
  margin-top: 1em;
  max-width: 100%;
  columns: 2;
  font-size: 1em;
  line-height: 1.2;
  gap: 2em;

  h4 {
    margin: 0.6em 0;
  }

  p {
    margin: 0;

    & + & {
      text-indent: 1em;
    }
  }

  ul {
    & > li:first-child {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 666px) {
    columns: 1;
    max-width: 80%;

    p {
      line-height: 1.4;
    }

    h4 {
      margin: 0.3em 0;
    }
  }

  @media only screen and (max-width: 480px) {
    max-width: 100%;

    p {
      line-height: 1.2;
    }
  }
`

const LinkLists = styled.section`
  padding-bottom: 5em;

  display: grid;
  grid-column: 2;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  gap: 1em;

  h3 {
    margin: 0;
    font-size: 1em;
    border-bottom: 2px solid;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 0.6em;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 1em 5em 1em;
  }
  @media only screen and (max-width: 666px) {
    grid-template-columns: 1fr 1fr;
    max-width: 80%;
  }
  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`
const Leistungen = ({ data }) => {
  const pageData = data.contentfulServicesPage

  return (
    <Layout>
      <SEO title={pageData.title} description={pageData.metaDescription} />
      <Pagehead bottomMargin>
        <OpenerImage fluid={pageData.openerImage.fluid} alt="" />
        <PageTitle>
          <RichText node={pageData.pageTitle} unwrapParagraphs />
        </PageTitle>
        <InquiryCallToAction>
          {pageData.inquiryCallToAction}
        </InquiryCallToAction>
        <Breadcrumbs nodes={[{ title: "Leistungen" }]} />
      </Pagehead>
      <Section>
        <Narrow>
          <Grid>
            <FlexRow>
              <FlexRowIllu>
                <Carpentry height="15em" />
              </FlexRowIllu>
              <FlexRowText>
                <RichText node={pageData.text1} h3Component={BlockHeading} />
              </FlexRowText>
            </FlexRow>
            <FlexRow>
              <FlexRowIllu>
                <Architects height="15em" />
              </FlexRowIllu>
              <FlexRowText>
                <RichText node={pageData.text2} h3Component={BlockHeading} />
              </FlexRowText>
            </FlexRow>
            <FlexRow>
              <FlexRowIllu>
                <Digital height="15em" />
              </FlexRowIllu>
              <FlexRowText>
                <RichText node={pageData.text3} h3Component={BlockHeading} />
              </FlexRowText>
            </FlexRow>
          </Grid>
          <Arbeitsweise>
            <h3>{pageData.closerHeading}</h3>
            <TwoColumns>
              <RichText node={pageData.closerBody} />
            </TwoColumns>
          </Arbeitsweise>
          <LinkLists>
            <h3>Netzwerke</h3>
            <ul>
              <li>
                <Link to="https://www.81fuenf.de/">81fünf AG</Link>
              </li>
              <li>
                <Link to="https://d-h-v.de/">DHV</Link>
              </li>
              <li>
                <Link to="https://informationsvereinholz.de/">
                  Informationsverein Holz
                </Link>
              </li>
              <li>
                <Link to="https://www.buildingsmart.de/">buildingSMART</Link>
              </li>
            </ul>
            <h3>Software</h3>
            <ul>
              <li>
                <Link to="https://www.mbaec.de/">
                  mb BauStatik, MicroFE, ViCADo
                </Link>
              </li>
              <li>
                <Link to="https://www.cadwork.de/">Cadwork</Link>
              </li>
              <li>
                <Link to="https://www.autodesk.de/products/revit/">Revit</Link>
              </li>
              <li>
                <Link to="https://abbund.com/">S+S Abbund</Link>
              </li>
              <li>
                <Link to="https://www.hottgenroth.de/">
                  Hottgenroth Energieberater
                </Link>
              </li>
              <li>
                <Link to="https://www.axavia.com/">AXAVIAseries</Link>
              </li>
            </ul>
          </LinkLists>
        </Narrow>
      </Section>
      <Contact />
    </Layout>
  )
}

export default Leistungen

export const query = graphql`
  query LeistungenQuery {
    contentfulServicesPage {
      title
      metaDescription
      openerImage {
        fluid {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      pageTitle {
        json
      }
      inquiryCallToAction
      text1 {
        json
      }
      text2 {
        json
      }
      text3 {
        json
      }
      closerHeading
      closerBody: closingBody {
        json
      }
    }
  }
`
